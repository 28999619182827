.bg-white {
    background-color: #ffffff;
}
.red-circle {
    border: 1px solid red;
    padding: 18px 0px;
    border-radius: 50%;
    line-height: 5px;
    text-align: center
}
.image-contains .MuiAvatar-img {
    object-fit: contain !important;
}

.MuiAvatar-img {
    object-fit: contain !important;
}